import useUrlState from '@ahooksjs/use-url-state';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FieldAutocompleteEditor } from '../../components/FieldAutocompleteEditor';
import { FieldEditor } from '../../components/FieldEditor';
import { FieldVisualEditor } from '../../components/FieldVisualEditor';
import { renderDate, Table } from '../../components/Table';
import { Tabs } from '../../components/Tabs';
import { TagProp, Tags } from '../../components/Tags';
import { VersionData, Versions } from '../../components/Versions';
import { getMetaDatabase, getMetaDatabaseVersion } from '../../services/pages/metadata';
import { getOwnRatingData, getRatingData } from '../../services/pages/rating';
import { getSystems } from '../../services/pages/systems';
import { getSystemDisplayValue, getTablePageSize, handleHttpError, i18n, rateClickedHandler, uuid } from '../../utils';
import styles from './MetaData.module.scss';

export function MetaDatabase() {
    const [isLoaded, setLoaded] = useState(false);

    const { id, version_id } = useParams();
    const [databaseId, setDatabaseId] = useState<string>('');
    const [databaseVersionId, setDatabaseVersionId] = useState<number|undefined>(undefined);
    const [data, setData] = useState<any>({});
    const [state, setState] = useUrlState({ t: '1', p1: '1', q1: '', p2: '1', q2: '', p3: '1', q3: '', p4: '1', q4: '' }, { navigateMode: 'replace' });
    const [tabs, setTabs] = useState<any[]>([]);
    const [ratingData, setRatingData] = useState({ rating: 0, total_rates: 0 });
    const [ownRating, setOwnRating] = useState(0);
    const [versions, setVersions] = useState<VersionData[]>([]);
    const [tags, setTags] = useState<TagProp[]>([]);

    const schemaColumns = [
        { property: 'id', header: 'ID', isHidden: true },
        { property: 'name', header: i18n('Название')},
        {
            property: 'modified',
            header: i18n('Дата изменения'),
            render: (row: any) => renderDate(row, 'modified'),
          }
    ];

    const tableColumns = [
        { property: 'id', header: 'ID', isHidden: true },
        { property: 'parent_name', header: i18n('Схема')},
        { property: 'name', header: i18n('Название')},
        {
            property: 'modified',
            header: i18n('Дата изменения'),
            render: (row: any) => renderDate(row, 'modified'),
          }
    ];

    const columnColumns = [
        { property: 'id', header: 'ID', isHidden: true },
        { property: 'schema_name', header: i18n('Схема') },
        { property: 'meta_object_name', header: i18n('Таблица/представление') },
        { property: 'name', header: i18n('Название') },
        { property: 'column_type', header: i18n('Тип данных') },
        { property: 'is_key', header: i18n('Ключ'), render: (row: any) => (row.is_key ? i18n('Да') : i18n('Нет')) },
        {
            property: 'modified',
            header: i18n('Дата изменения'),
            render: (row: any) => renderDate(row, 'modified'),
          }
    ];

    useEffect(() => {
        if (id)
            setDatabaseId(id);
        if (version_id)
            setDatabaseVersionId(parseInt(version_id));
        else
            setDatabaseVersionId(undefined);
    }, [ id, version_id ]);

    useEffect(() => {
        if (databaseId) {
            
            console.log('load ' + databaseId, databaseVersionId);

            getMetaDatabase(databaseId).then(json => {
                setVersions( Array.from({length: json.version_id - 1}, (_, i) => i + 1).reverse().map(vid => ({
                    name: 'Версия ' + vid,
                    description: '',
                    version_id: '' + vid,
                    created_at: '',
                    modifier_display_name: null,
                    modifier_email: null,
                    modifier_description: null
                })) );
            }).catch(handleHttpError);

            (databaseVersionId ? getMetaDatabaseVersion(databaseId, databaseVersionId) : getMetaDatabase(databaseId)).then(json => {
                setData(json);

                setTags(
                    json.tags ? json.tags.map((x: any) => ({ value: x.entity.name })) : [],
                  );

                getRatingData(databaseId)
                    .then((json) => {
                        setRatingData(json);
                    }).catch(handleHttpError);

                getOwnRatingData(databaseId).then((rating) => {
                        setOwnRating(rating);
                    }).catch(handleHttpError);

                setTabs([
                    {
                        key: 'tab-1-' + databaseId + '-' + databaseVersionId,
                        id: 'tab-1',
                        title: i18n('Схемы'),
                        content: <div>
                            <Table key={'tbl-1-' + databaseId + '-' + databaseVersionId} cookieKey='md-schemas' className={styles.table} columns={schemaColumns} paginate columnSearch globalSearch dataUrl='/v1/metadata/object/search' limitSteward={false}
                                initialFetchRequest={{
                                    sort: 'name+',
                                    global_query: state.q1 !== undefined ? state.q1 : '',
                                    limit: getTablePageSize(),
                                    offset: (state.p1 - 1) * getTablePageSize(),
                                    filters: [],
                                    filters_preset: [ { column: 'meta_object_type', value: 'SCHEMA', operator: 'EQUAL' }, { column: 'meta_database_id', value: databaseId, operator: 'EQUAL' }, { column: 'version_id', value: databaseVersionId ?? json.version_id, operator: 'EQUAL' } ],
                                    filters_for_join: [],
                                }}
                                onRowClick={(row: any) => {}}
                                onPageChange={(page: number) => (
                                    setState(() => ({ p1: page }))
                                )}
                                onQueryChange={(query: string) => (
                                    setState(() => ({ p1: undefined, q1: query }))
                                )}
                                  />
                        </div>
                    },
                    {
                        key: 'tab-2-' + databaseId + '-' + databaseVersionId,
                        id: 'tab-2',
                        title: i18n('Таблицы'),
                        content: <div>
                            <Table key={'tbl-2-' + databaseId + '-' + databaseVersionId} cookieKey='md-tables' className={styles.table} columns={tableColumns} paginate columnSearch globalSearch dataUrl='/v1/metadata/object/search' limitSteward={false}
                                initialFetchRequest={{
                                    sort: 'name+',
                                    global_query: state.q2 !== undefined ? state.q2 : '',
                                    limit: getTablePageSize(),
                                    offset: (state.p2 - 1) * getTablePageSize(),
                                    filters: [],
                                    filters_preset: [ { column: 'meta_object_type', value: 'TABLE', operator: 'EQUAL' }, { column: 'meta_database_id', value: databaseId, operator: 'EQUAL' }, { column: 'version_id', value: databaseVersionId ?? json.version_id, operator: 'EQUAL' } ],
                                    filters_for_join: [],
                                }}
                                onRowClick={(row: any) => {}}
                                onPageChange={(page: number) => (
                                    setState(() => ({ p2: page }))
                                )}
                                onQueryChange={(query: string) => (
                                    setState(() => ({ p2: undefined, q2: query }))
                                )}
                                  />
                        </div>
                    },
                    {
                        key: 'tab-3-' + databaseId + '-' + databaseVersionId,
                        id: 'tab-3',
                        title: i18n('Представления'),
                        content: <div>
                            <Table key={'tbl-3-' + databaseId + '-' + databaseVersionId} cookieKey='md-views' className={styles.table} columns={tableColumns} paginate columnSearch globalSearch dataUrl='/v1/metadata/object/search' limitSteward={false}
                                initialFetchRequest={{
                                    sort: 'name+',
                                    global_query: state.q3 !== undefined ? state.q3 : '',
                                    limit: getTablePageSize(),
                                    offset: (state.p3 - 1) * getTablePageSize(),
                                    filters: [],
                                    filters_preset: [ { column: 'meta_object_type', value: 'VIEW', operator: 'EQUAL' }, { column: 'meta_database_id', value: databaseId, operator: 'EQUAL' }, { column: 'version_id', value: databaseVersionId ?? json.version_id, operator: 'EQUAL' } ],
                                    filters_for_join: [],
                                }}
                                onRowClick={(row: any) => {}}
                                onPageChange={(page: number) => (
                                    setState(() => ({ p3: page }))
                                )}
                                onQueryChange={(query: string) => (
                                    setState(() => ({ p3: undefined, q3: query }))
                                )}
                                  />
                        </div>
                    },
                    {
                        key: 'tab-4-' + databaseId + '-' + databaseVersionId,
                        id: 'tab-4',
                        title: i18n('Колонки'),
                        content: <div>
                            <Table key={'tbl-4-' + databaseId + '-' + databaseVersionId} cookieKey='md-cols' className={styles.table} columns={columnColumns} paginate columnSearch globalSearch dataUrl='/v1/metadata/column/search' limitSteward={false}
                                initialFetchRequest={{
                                    sort: 'name+',
                                    global_query: state.q4 !== undefined ? state.q4 : '',
                                    limit: getTablePageSize(),
                                    offset: (state.p4 - 1) * getTablePageSize(),
                                    filters: [],
                                    filters_preset: [ { column: 'meta_database_id', value: databaseId, operator: 'EQUAL' }, { column: 'version_id', value: databaseVersionId ?? json.version_id, operator: 'EQUAL' } ],
                                    filters_for_join: [],
                                }}
                                onRowClick={(row: any) => {}}
                                onPageChange={(page: number) => (
                                    setState(() => ({ p4: page }))
                                )}
                                onQueryChange={(query: string) => (
                                    setState(() => ({ p4: undefined, q4: query }))
                                )}
                            />
                        </div>
                    }
                ]);

                setLoaded(true);
            }).catch(handleHttpError);

            
        }
    }, [ databaseId, databaseVersionId ]);

    const getSystemObjects = async (search: string) => getSystems({
        sort: 'name+',
        global_query: search,
        limit: 1000,
        offset: 0,
        filters: [],
        filters_for_join: [],
      }).then((json) => json.items);
    
    return (
        <div className={classNames(styles.page, styles.metadataPage, { [styles.loaded]: isLoaded })}>
            <div className={styles.mainContent}>
                <div className={styles.title}>{i18n('МЕТАДАННЫЕ')}: {data.name}</div>

                <Tags
                    key={'tags-' + databaseId + '-' + databaseVersionId + '-' + uuid()}
                    isReadOnly={true}
                    tags={tags}
                    
                />

                <div className={styles.description}>
                    <FieldVisualEditor
                        isReadOnly={true}
                        labelPrefix={`${i18n('Описание')}:`}
                        defaultValue={data.description}
                        className={styles.editor}
                        valueSubmitted={(val) => {
                        
                        }}
                    />
                </div>

                <div className={styles.system}>
                    <FieldAutocompleteEditor
                        className=""
                        label={i18n('Система')}
                        defaultValue={data.system_id}
                        valueSubmitted={(identity) => {}}
                        getDisplayValue={getSystemDisplayValue}
                        getObjects={getSystemObjects}
                        isRequired
                        isReadOnly={true}
                        showValidation={false}
                        artifactType='system'
                    />
                </div>

                <div className={styles.jdbc_url}>
                    <FieldEditor
                        isReadOnly={true}
                        layout="separated"
                        labelPrefix={`${`${i18n('Сервер')}:`} `}
                        defaultValue={data.jdbc_url}
                        className={styles.long_input}
                        valueSubmitted={(val) => { }}
                        onBlur={(val) => { }}
                    />
                </div>

                
                <Tabs key={'tabs-' + databaseId + '-' + databaseVersionId} tabs={tabs} tabNumber={state.t} onTabChange={(tab: number) => { setState(() => ({ t: tab })); }} />
            </div>
            <div className={styles.rightBar}>
                
                <Versions
                    rating={ratingData.rating}
                    ownRating={ownRating}
                    version_id={data.version_id}
                    versions={versions}
                    version_url_pattern={`/metadata/db/${encodeURIComponent(databaseId)}/version/{version_id}`}
                    root_object_url={`/metadata/db/${encodeURIComponent(databaseId)}`}
                    onRateClick={r => rateClickedHandler(r, databaseId, 'meta_database', setOwnRating, setRatingData)}
                />
                
            </div>
        </div>
    );
}